import './App.css';
import NewsData from './components/NewsData';

function App() {
  return (
    <NewsData/>
  );
}

export default App;
